// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fwGfekqiNH814X2oj8KE {
	font-weight: bold;
}

.OcI03wCgHSKzB1u8GUk8 {
	font-style: italic;
}

.LBrZ35hFAyLJjrkfKOQp {
	text-decoration: underline;
}

.DrWvrAkeKBGRI5iFjMkt {
	text-decoration: line-through;
}

.BX7_6qBop_YRrn1OO10z {
	vertical-align: sub;
}

.hx5kj8mvalvUh2gW2Uia {
	vertical-align: super;
}
`, "",{"version":3,"sources":["webpack://./src/styles/text-styles.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".bold {\n\tfont-weight: bold;\n}\n\n.italic {\n\tfont-style: italic;\n}\n\n.underline {\n\ttext-decoration: underline;\n}\n\n.strikethrough {\n\ttext-decoration: line-through;\n}\n\n.subscript {\n\tvertical-align: sub;\n}\n\n.superscript {\n\tvertical-align: super;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bold": `fwGfekqiNH814X2oj8KE`,
	"italic": `OcI03wCgHSKzB1u8GUk8`,
	"underline": `LBrZ35hFAyLJjrkfKOQp`,
	"strikethrough": `DrWvrAkeKBGRI5iFjMkt`,
	"subscript": `BX7_6qBop_YRrn1OO10z`,
	"superscript": `hx5kj8mvalvUh2gW2Uia`
};
export default ___CSS_LOADER_EXPORT___;
